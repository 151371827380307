import './Sentry';
import * as Sentry from "@sentry/react";
import './bootstrap';
import '../css/app.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import {NextUIProvider} from "@nextui-org/system";
const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Chroma Cubs';
import.meta.glob([
    '../images/**',
]);

createInertiaApp({
    title: (title) => `${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
    setup({ el, App, props }) {
        const root = createRoot(el);
        const ErrorView = ({ error, info }) => (
            <div className={'flex items-center flex-col justify-center'} style={{ backgroundColor: "white", width: "100%", height: "100%", color: "black", padding: "20px" }}>
                <img alt={'405 error'} src={'./img/errors/405.jpg'} className={'w-[200px] h-[200px]'} />
                <div className={'text-center text-1'}>Something is wrong. It could be a technical error or a coup d'état. We are investigating.</div>
                <div className={'text-center text-2 mt-[20px] '}>Thank you for your patience</div>

                {window.App.env!=='production' &&
                 <div className={'w-full p-[10px] overflow-x-scroll'}>
                    Player id: {window.App.user_id}
                  </div>
                }

            </div>
        );
        const myFallback = <ErrorView />;

        root.render(
            <Sentry.ErrorBoundary fallback={myFallback} showDialog>
            <NextUIProvider>

                <App {...props} />
            </NextUIProvider>
            </Sentry.ErrorBoundary>

        );    },
    progress: {
        color: '#4B5563',
    },
});
